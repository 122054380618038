form
    width: 400px
    label
        display: block
        input
            display: block
            margin: 20px auto
            padding: 5px
            width: 80%
        textarea
            display: block
            margin: 20px auto
            padding: 5px
            width: 80%
            min-height: 20vh
    #mail
        display: inline
        width: 30%
        margin-left: 35px
    button
        margin-left: 40px
        padding: 2px
        font-size: 1.2em

@media  ( max-width:720px )

    form
        width: 300px
        margin: 0 auto
