////header
$headerBackgroundColor: grey

////// Nav 
$navActiveTextColor: black
$navTextColor: #666

//HOme
$homeTextColor: #333
//footer
$footerTextColor: #444
$footerHoverColor: #000

//// contact
$contactTextColor: #666
$contactHoverColor: #000

///yoytube
$youtubeTextColor: #666
$youtubeHoverColor: #000
