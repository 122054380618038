.articles
    flex-basis: 80%
.articles__article-date, .articles__article-description
    color: #999999
    font-size: 16px
    padding: 10px
.articles__article-title
    color:#CC6601
.articles__article-description
    padding: 30px
    line-height: 24px
.articles__gallery, .articles__movies
    display: flex
    justify-content: space-evenly
    flex-wrap: wrap
.articles__image
    width:300px
    margin: 15px auto
.articles__article--hide
    height: 400px
    overflow: hidden
.articles__read-more
    font-size: 16px
    padding: 20px
    cursor: pointer
.articles__movies iframe
    width:500px
    height: 400px
    border:0

hr
    margin-bottom:20px