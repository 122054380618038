.rejestr
    max-width: 60%
    margin: 20px auto

    p
        margin: 30px auto
    table
        min-width: 100%

        border-collapse: collapse
        thead, tbody, th, td
            border: 1px solid black
        th
            border-width: 2px
@media ( max-width: 720px  )
    .rejestr
        max-width: 80%
