footer
    background-color: #ddd
    padding: 20px
    position: relative
    h2
        font-size: 0.7em

    div
        text-align: center
        font-size: 50px
        span
            margin: 5px
            color: $footerTextColor
            a
                color: $footerTextColor
                &:hover
                    color: $footerHoverColor
    p

        width: 50%
        margin: 20px auto
        text-align: center
        font-size: 13px
    .contact
        position: absolute
        width: 250px
        right: 20%
        top: 10px
        h2
            font-size: 0.5em
        p
            line-height: 1px
.footer-logo
    width: 80px
    position: absolute
    right: 20px
    top:20px
@media ( max-width: 720px )
    footer
        p
            width: 80%
