.cookies
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50% ,-50%)
    width: 40%
    background-color: white
    padding: 5px
    border:2px solid black
    font-size: 0.7em
    p
        padding: 20px
    button
        color: white
        display: block
        background-color: black
        margin:0 auto
        padding: 10px
        border: 1px solid black
        font-weight: 600
        transition: 0.3s
        &:hover
            color: black
            background-color: white

@media ( max-width: 720px  )
    .cookies
        width: 80%
    
