div.photo
    max-width: 70%
    margin: 20px auto
h1
    margin-bottom: 20px
    text-align: center
    color: $homeTextColor
p
    font-size: 1.4em
    text-align: justify
    line-height: 1.3em
  
