.kontakt
    padding: 30px
    h1
        margin-bottom: 20px
        text-align: center
        color: $homeTextColor
    section
        display: flex
        justify-content: space-between
        align-items: center
        iframe
            flex-basis: 50%
            min-height: 40vh
            border:0
    p

        text-align: center
        padding: 0 20px 0 20px
        a
            text-decoration: none
            color: $contactTextColor
            transition: 0.2s
            &:hover
                color: $contactHoverColor
                font-weight: 600

@media ( max-width: 720px )
    .kontakt
        section

            display: block
            iframe

                display: block
                min-width: 80%
                margin: 0 auto
