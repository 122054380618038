*
    margin: 0
    padding: 0
    font-family: Lato, Helvetica, sans-serif
h1
    text-transform: uppercase
    font-size: 1.5em
html
    background-image: url("../img/background.jpg")
    background-repeat: no-repeat
    background-attachment: fixed
    background-size: cover
    body
        background-color: rgba(255,255,255,0.8)

    

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;700&display=swap')
@import 'variables'
@import 'header'
@import 'nav'
@import 'home'
@import 'main'
@import 'aboutme'
@import 'footer'
@import 'rejestr'
@import 'kontakt'
@import 'form'
@import 'youtube'
@import 'cookies'
@import 'news'
@import '../blog/blogcss/blog'